import styled from 'styled-components'

export const PageContainer = styled.div`
  background-color: white;
  padding: 0 5vw;
  // min-height: 80vh;
`;

export const PageHeader = styled.div`
  font-weight: 500;
  padding: 16px 0;
  background-color: white;
`;
